var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f7f388ab204385437757468d2703be10d33d1169"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { getSentryConfig } from '@patrianna/shared-utils/getSentryConfig'

const ignoreErrorCodes = ['err_frozen', 'err_auth']

Sentry.init(
  getSentryConfig({
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    denyUrls: [
      /api\.uk\.exponea\.com/,
      /webkit-masked-url/i,
      /exponea/i,
      /clarity\.ms/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /safari-web-extension/i,
    ],

    beforeSend(event, hint) {
      const error = hint.originalException

      if (ignoreErrorCodes.includes((error as any)?.status?.errorCode)) {
        return null
      }

      return event
    },

    ignoreErrors: [
      'globalThis is not defined', // error occurs in the not supported browsers
    ],
    tracesSampleRate: 1,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  })
)
